/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { RouteHelper } from "@nf/common";
import { useMemo } from "react";
import { DefaultSeo } from "next-seo";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { fallbackPageMetadata } from "./pageMetadata";

interface ILanguageAlternates {
  hrefLang: string;
  href: string;
}

export const DefaultSEO = () => {
  const router = useRouter();
  const { t } = useTranslation("translations");
  const href = process.env.NEXT_PUBLIC_MAIN_DOMAIN + router.asPath;
  //dynamic href for all language alternates
  const getLanguageAlternates: ILanguageAlternates[] = useMemo(() => {
    return router.domainLocales
      ? router.domainLocales.map((obj) => {
          //hreflang code
          const hrefLangCode = obj.defaultLocale.replace("_", "-");

          //dynamic route for all hreflangs
          const language = obj.defaultLocale.split("_")[0];
          const country = obj.defaultLocale.split("_")[1].toLowerCase();
          const newLanguagePath = router.pathname.replace(
            "[country]/[language]",
            `${country}/${language}`
          );
          const newPathname = newLanguagePath.replace(
            "[id]",
            `${router.query.id}`
          );

          return {
            hrefLang: hrefLangCode,
            href: process.env.NEXT_PUBLIC_MAIN_DOMAIN + newPathname,
          };
        })
      : [];
  }, [router.pathname, router.query.id, router.domainLocales]);

  const landingPageTabTitle =
    !router.query.id &&
    t("web_seo_cta_title_tags_landing_page", process.env.NEXT_PUBLIC_NAME);

  const tagTitle = landingPageTabTitle || process.env.NEXT_PUBLIC_NAME;

  const canonicalPathname = RouteHelper.getCanonicalUrl(router.pathname);
  const canonicalUrl = RouteHelper.replacePathSegments(
    canonicalPathname,
    router.query
  );

  return (
    <DefaultSeo
      title={tagTitle}
      titleTemplate={`%s`}
      canonical={canonicalUrl}
      defaultTitle={process.env.NEXT_PUBLIC_NAME}
      description={fallbackPageMetadata.default.description}
      languageAlternates={[...getLanguageAlternates]}
      additionalMetaTags={[
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, shrink-to-fit=no",
        },
        {
          name: "keywords",
          content: "movie, series, film, video",
        },
      ]}
      openGraph={{
        title: tagTitle,
        description: fallbackPageMetadata.default.description,
        type: "website",
        url: href,
        site_name: process.env.NEXT_PUBLIC_NAME,
        images: [{ url: "/assets/app/app-header-logo.png" }],
      }}
    />
  );
};

export default DefaultSEO;
