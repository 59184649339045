export const fallbackPageMetadata = {
  default: {
    title: "Nordisk Film+",
    description: "Nordisk Film+",
  },
  hjem: {
    title: "Største udvalg af danske film | Kun 59 kr./md.",
    description:
      "Med Nordisk Film+ kan du se og streame det største udvalg af danske film. Du får også håndplukkede film fra Hollywood og et trygt børneunivers. Kun 59 kr./md. Filmhyggen er hjemme.",
  },
  danskfilmstat: {
    title: "Elskede filmklassikere | Kun 59 kr./md.",
    description:
      "Kom i godt selskab med Olsen Banden, Dirch Passer, Lille Per og alle de elskede klassikere fra Dansk Filmskat. Filmhyggen er hjemme for kun 59 kr. per måned.",
  },
  boern: {
    title: "Sjovt og trygt børneunivers | Kun 59 kr./md.",
    description:
      "Familiens yngste kan boltre sig i et sjovt og trygt børneunivers fyldt med film og serier fra Nordisk Film. Filmhyggen er hjemme for kun 59 kr. per måned.",
  },
};

export const pageMetaDataTranslationKeys = {
  landingpage: {
    title: "web_home_page_title_landingpage",
    description: "web_home_description_landingpage",
  },
  hjem: {
    title: "web_home_page_title",
    description: "web_home_description",
  },
  danskfilmskat: {
    title: "web_home_page_title_dfs",
    description: "web_home_description_dfs",
  },
  boern: {
    title: "web_home_page_title_kids",
    description: "web_home_description_kids",
  },
};

export type PageMetadataKeys = keyof typeof pageMetaDataTranslationKeys;
export type PageMetadataFallbackKeys = keyof typeof fallbackPageMetadata;
